import axios from 'axios'

const getBase64FromS3 = (key) => {
  return new Promise((resolve, reject) => {
    axios.get(`https://img.vjp.io/image/${key}`)
      .then(response => {
        if (response.data.base64)
          resolve(response.data.base64)
        else
          reject(null)
      }).catch(error => {
        reject(error)
      })
  })
}

const uploadImagenToS3 = (key, data) => {
  // data: { imageBase64: imagen.base64, type: imagen.type }
  return new Promise((resolve, reject) => {
    axios.post(`https://img.vjp.io/image/upload/${key}`, data)
      .then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
  })
}

export default { getBase64FromS3, uploadImagenToS3 }
