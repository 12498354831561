<template>
    <v-container pa-2 fluid fill-height class="fill-height" :style="$vuetify.theme.dark ? 'background: transparent!important;' : 'background: #f5f5fb!important;' + 'display: grid;'">
        <v-layout column style="display: block !important; background: transparent;">
            <v-card style="background: transparent;" color="transparent" class="elevation-0"
                    :class="{'pa-1 ma-2' : $vuetify.breakpoint.smAndDown, 'pa-2 ma-4' : !$vuetify.breakpoint.smAndDown}">
                <slot/>
            </v-card>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "Wrapper"
    }
</script>

<style scoped>

</style>
