<template>
    <v-dialog v-model="$alerta.model" scrollable persistent :max-width="$alerta.ancho + 'px'"
              @keydown.esc="$alerta.model = false" transition="dialog-top-transition">
        <v-card style="border-radius: 15px">
            <v-card-text>
                <v-row justify="center" align="center" class="pa-9">
                    <v-col v-if="$alerta.img" cols="12" sm="10" md="9" lg="8" class="text-center">
                        <v-img :src="$alerta.img"/>
                    </v-col>
                    <v-col v-else cols="12" sm="12" class="text-center">
                        <v-icon :class="$alerta.color +'--text'" size="50px">{{ $alerta.icon }}</v-icon>
                    </v-col>
                    <v-col cols="12" sm="12" class="text-center">
                        <h2>{{ $alerta.mensaje }}</h2>
                    </v-col>
                </v-row>
                <slot/>
            </v-card-text>

            <v-card-actions v-if="!$alerta.isSnackbar">
                <v-spacer/>
                    <v-btn text plain @click="$alerta.model = false">Cerrar</v-btn>
                    <v-btn v-if="$alerta.accion != null" text plain color="primary" @click="() => { $alerta.accion() }">Aceptar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "Alerta"
    }
</script>

<style scoped>

</style>
