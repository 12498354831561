<template>
  <v-card flat class="ma-0 pa-0">
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card-text class="text-center d-flex flex-column justify-space-between align-center">

          <v-avatar :size="size + 'px'">
          <v-img :height="size" :width="size" :src="base64">
            <template v-slot:placeholder>
              <v-avatar :size="size + 'px'">
                <v-icon :size="size + 'px'"  color="grey">{{ iconString }}</v-icon>
              </v-avatar>
            </template>
          </v-img>
          </v-avatar>

          <template v-if="updatable">
            <v-fade-transition>
              <v-overlay v-if="hover" absolute>
                <v-icon size="60px">mdi-camera</v-icon>
                <h2>Subir foto</h2>
              </v-overlay>
            </v-fade-transition>
            <input type="file" accept='image/*' class="input-file" @change="guardarImagen">
          </template>
        </v-card-text>
      </template>
    </v-hover>
  </v-card>
</template>

<script>
  import ImagenesService from "../../services/ImagenesService";

  export default {
    name: "Foto",
    props: {
      id: { type: [ Number, String ], required: true },
      size: { type: [ Number, String ], default: 250 },
      type: { type: String, required: true },
      updatable: { type: Boolean, default: false }
    },
    data () {
      return {
        base64: '',
        iconString: this.type === 'usuario' ? 'mdi-account-circle' : 'mdi-car',
        urlImg: 'envios-' + this.type + '-' + this.id
      }
    },
    created () {
      let vm  = this
      ImagenesService.getBase64FromS3(this.urlImg)
        .then(base64 => (vm.base64 = base64))
        .catch(() => (vm.base64 = ''))
    },
    methods: {
      guardarImagen (e) {
        let vm = this
        let file = e.target.files[0]
        let type = file.type

        let reader = new FileReader()
        if (file) {
          vm.$loader = true
          reader.onload = () => {
            let imageBase64 = reader.result
            ImagenesService.uploadImagenToS3(vm.urlImg, { imageBase64, type })
              .then(() => (vm.base64 = imageBase64))
              .finally(() => (vm.$loader = false))
          }
          reader.readAsDataURL(file)
        }
      }
    }
  }
</script>

<style scoped>
  .input-file {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 6;
    cursor: pointer
  }
</style>
