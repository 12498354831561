import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/firestore'
import AuthConst from './AuthConst'

const app = firebase.initializeApp(AuthConst.FIREBASE_OPTIONS)
const auth = firebase.auth()
firebase.analytics()
let analytics = firebase.analytics()

const microsoftAuthProvider = new firebase.auth.OAuthProvider('microsoft.com')

let getCollection = (collectionName) => {
  return app.firestore().collection(collectionName)
}

let microsoftSignIn = () => {
  auth.signInWithRedirect(microsoftAuthProvider)
}

let getRedirectResult = () => {
  return new Promise((resolve, reject) => {
    auth.getRedirectResult().then((result) => {
      let token, user
      if (result.credential) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        token = result.credential.accessToken
        user = { nombre: result.user.displayName, email: result.user.email, photoURL: result.user.photoURL }
      }
      resolve({token, user})
    }).catch((error) => {
      reject(error)
    })
  })
}

let getActiveUser = (callback) => {
  auth.onAuthStateChanged((user) => {
    // USUARIO ACTIVO
    callback(user)
  })
}

let signOut = () => {
  auth.signOut()
}

let Firebase = { analytics, getCollection, getRedirectResult, microsoftSignIn, getActiveUser, signOut }

export default Firebase
