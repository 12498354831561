import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import route from '@/route';

import './global/GlobalData'
import './global/GlobalComponents'
import './global/Filters'

Vue.config.productionTip = false;
const router = route.getRouter(Vue)

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
