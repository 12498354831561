<template>
  <v-app>
    <bar v-if="$route.name != 'Login'"/>
    <v-main>
      <router-view/>
    </v-main>
    <loader/>
    <alerta/>
  </v-app>
</template>

<script>
import Bar from "./components/General/Bar";
import AuthConst from "./config/AuthConst";
import Loader from "./components/Loader";
import Alerta from "./components/General/Alerta";

export default {
  name: "App",
  components: {Alerta, Loader, Bar},
  data: () => ({
    //
  }),
  created() {
    // Guarda información del usuario
    let usuario = JSON.parse(localStorage.getItem(AuthConst.CURRENT_USER_KEY))
    if (usuario && usuario.id) {
      this.$usuario = usuario

      this.$vuetify.theme.themes.light.primary = usuario.tema.color
      this.$vuetify.theme.themes.dark.primary = usuario.tema.color
      this.$vuetify.theme.dark = usuario.tema.isDark
    }
  }
};
</script>
