<template>
    <v-app-bar app clipped-left flat style="z-index: 8">
        <img v-if="!$vuetify.breakpoint.smAndDown" :src="require('../../assets/logoVanjupe.png')"
              @click="$router.push({ name: 'Dashboard' })" />
        <v-spacer/>

        <template v-if="$usuario.administrador">
            <v-btn :to="{ name: 'Dashboard' }" exact text>Inicio</v-btn>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="mr-2">
                        <v-icon>mdi-cog</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(item, index) in items" :key="index" @click="item.accion">
                        <v-list-item-avatar><v-icon class="primary--text">mdi-{{ item.icon }}</v-icon></v-list-item-avatar>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>

        <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-avatar>
                        <foto :id="$usuario.id" type="usuario" size="48" />
                    </v-avatar>
                </v-btn>
            </template>
            <perfil/>
        </v-menu>
        <dialogo v-model="$dialogCrudOperador" :title="$operadorParaGuardar.id ? 'Editar usuario' : 'Agregar usuario'">
            <v-form v-model="validacionOperador" ref="formOperador">
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="$operadorParaGuardar.nombre" label="Nombre" autofocus :rules="nameRules"/>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="$operadorParaGuardar.email" label="Correo" :rules="nameRules"/>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="$operadorParaGuardar.telefono" label="Télefono" :rules="nameRules"/>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="$operadorParaGuardar.zonaPredeterminada" label="Zona predeterminada"
                                :items="zonas" item-value="clave" item-text="nombre" />
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="$operadorParaGuardar.gestor" label="Gestor" />
                    </v-col>
                </v-row>
            </v-form>
            <template slot="actions">
                <v-btn text color="primary" plain :disabled="!validacionOperador" @click="$operadorParaGuardar.id ? editarOperador() : agregarOperador()">Aceptar</v-btn>
            </template>
        </dialogo>
        <dialogo v-model="dialogCrudUnidad" :title="unidadParaGuardar.id ? 'Editar unidad' : 'Agregar unidad'">
            <v-form v-model="validacionUnidad" ref="form">
            <v-row>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="unidadParaGuardar.marca" label="Marca" :rules="nameRules"/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="unidadParaGuardar.submarca" label="Submarca"/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="unidadParaGuardar.modelo" label="Modelo" :rules="nameRules"/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="unidadParaGuardar.tipoPlacas" label="Tipo de placas"/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="unidadParaGuardar.placas" label="Placas" :rules="nameRules"/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="unidadParaGuardar.combustible" label="Combustible"/>
                </v-col>
            </v-row>
            </v-form>
            <template slot="actions">
                <v-btn text color="primary" plain :disabled="!validacionUnidad" @click="agregarUnidad()">Aceptar</v-btn>
            </template>
        </dialogo>
    </v-app-bar>
</template>

<script>
    import Perfil from "./Perfil";
    import OperadoresService from "../../services/OperadoresService";
    import FlotillaService from "../../services/FlotillaService";
    import Foto from "./Foto";
    import RutasService from "@/services/RutasService";
    export default {
        name: "Bar",
        components: {Foto, Perfil},
        data(){
            return{
                items: [
                    { title: 'Agregar usuario',
                        accion: () =>
                          (this.$abrirDialogOperador(
                            {idUnidad: null, administrador: false, tema: { color: '#1976D2', isDark: false }}
                          )),
                        icon: 'account-multiple-plus' },
                    { title: 'Agregar unidad', accion: () => (this.abrirDialogUnidad()), icon: 'car-2-plus' },
                    { title: 'Zonas', accion: () => (this.$router.push("Zonas")), icon: 'map-clock' }
                ],
                abrirPerfil: false,
                operadorParaGuardar: {},
                unidadParaGuardar: {},
                dialogCrudUnidad: false,
                nameRules: [v => !!v || 'Este campo es requerido'],
                validacionUnidad: false,
                validacionOperador:false,
                zonas: RutasService.getZonasConClave()
                  .map(({zona}) => ({ clave: zona, nombre: this.$getZonaNombre(zona) }))
            }
        },
        watch: {
          $dialogCrudOperador(val){
              if (val)
                this.$nextTick(() => (this.$refs.formOperador.resetValidation()));
            }
        },
        methods:{
            abrirDialogUnidad () {
                this.unidadParaGuardar = {}
                this.dialogCrudUnidad = true
            },
            async agregarOperador(){
                this.$loader = true
                try {
                    let operadorAgregado = await OperadoresService.saveOperador(this.$operadorParaGuardar)
                    this.$operadores.push(operadorAgregado)
                    this.$showAlerta('600', 'success' , 'mdi-check-circle-outline', '¡Operador guardado exitosamente!', true )
                }catch (e) {
                    console.log(e)
                }
                this.$dialogCrudOperador = false
                this.$loader = false
            },
            async editarOperador(){
                this.$loader = true
                try {
                    let operadorEditado = await OperadoresService.updateOperador(this.$operadorParaGuardar)
                    let index = this.$getIndex(this.$operadores, 'id', operadorEditado)
                    this.$set(this.$operadores, index, operadorEditado)
                    this.$showAlerta('600', 'success' , 'mdi-check-circle-outline', '¡Operador editado exitosamente!', true )
                }catch (e) {
                    console.log(e)
                }
                this.$loader = false
                this.$dialogCrudOperador = false
            },
            async agregarUnidad(){
                this.$loader = true
                try {
                    let unidadAgregada = await FlotillaService.saveUnidad(this.unidadParaGuardar)
                    this.$flotilla.push(unidadAgregada)
                    this.$showAlerta('600', 'success' , 'mdi-check-circle-outline', '¡Unidad guardada exitosamente!', true )
                } catch (e) {
                    console.log(e)
                }
                this.$loader = false
                this.dialogCrudUnidad = false
            }
        }
    }
</script>

<style scoped>

</style>
