import VueRouter from 'vue-router'
import AuthConst from './config/AuthConst'

const Dashboard = () => import('./components/Dashboard')
const Login = () => import('./components/Login')
const Zonas = () => import('./components/Zonas')

const routes = [
    { path: '/', component: Dashboard, name: 'Dashboard' },
    { path: '/login', component: Login, name: 'Login' },
    { path: '/zonas', component: Zonas, name: 'Zonas' }
]

// Función para cargar el route
let getRouter = (vue) => {
    vue.use(VueRouter)
    const router = new VueRouter({ routes, mode: 'history' })

    router.beforeEach((to, from, next) => {
        if (localStorage.getItem(AuthConst.CURRENT_USER_KEY) == null) {
            // Si el usuario aún no ha iniciado sesión
            if (to.name !== 'Login') {
                next('/login')
            } else {
                next()
            }
        } else {
            // Si el usuario ha iniciado sesión
            if (to.name === 'Login') {
                next('/')
            } else {
                let usuario = JSON.parse(localStorage.getItem(AuthConst.CURRENT_USER_KEY))
                // Si el usuario es administrador
                if (to.matched.some(record => record.meta.administrador)) {
                    if (usuario.administrador) {
                        next()
                    } else {
                        next('/')
                    }
                } else {
                    next()
                }
            }
        }
    })

    return router
}

export default { getRouter }
