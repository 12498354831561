<template>
    <v-card class="mx-auto" max-width="300" elevation="0">
        <v-card-title class="text-right mx-2">
            <v-row>
                <v-col cols="9">
                    <small>{{ $usuario.nombre }}</small>
                    <h4 class="caption">{{ $usuario.email }}</h4>
                    <h4 class="caption">{{ $usuario.telefono }}</h4>
                    <v-chip color="primary" x-small>
                        {{ $usuario.administrador ? 'Administrador' : ($usuario.gestor ? 'Gestor' : 'Usuario') }}
                    </v-chip>
                    <v-btn v-if="!$usuario.administrador && $usuario.gestor" rounded outlined x-small
                           @click="$verComoOperador = !$verComoOperador">
                        {{ 'Ver como ' + ($verComoOperador ? 'administrador' : 'operador') }}
                    </v-btn>
                </v-col>
                <v-col cols="3">
                    <v-avatar>
                        <foto :id="$usuario.id" type="usuario" size="48" />
                    </v-avatar>
                </v-col>
            </v-row>
        </v-card-title>
        <v-divider class="mx-2"/>
        <v-card-text>
            <v-row align="center" justify="center">
                <v-col v-for="(color, index) in colors" :key="index" cols="2">
                    <v-btn icon dark @click="cambiarColor(color.color)">
                        <v-avatar :color="color.color" size="36">
                            <v-icon v-if="$vuetify.theme.themes.light.primary === color.color">
                                mdi-checkbox-blank-circle-outline
                            </v-icon>
                        </v-avatar>
                    </v-btn>
                </v-col>
                <v-switch v-model="$vuetify.theme.dark" color="primary" label="Modo oscuro" @change="guardarTema" />
            </v-row>
        </v-card-text>
        <v-divider class="mx-2"/>
        <v-card-text>
            <v-row>
                <v-col cols="12" class="text-sm-center">
                    <v-btn text x-small plain @click="cerrarSesion">
                        <v-icon>mdi-logout</v-icon>
                        Cerrar sesión
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import firebase from '../../config/Firebase'
    import AuthConst from '../../config/AuthConst'
    import OperadoresService from '../../services/OperadoresService'
    import Foto from "./Foto";

    export default {
        name: "Perfil",
        components: {Foto},
        data(){
            return{
                colors: [
                    { color: '#1976D2'},
                    { color: '#ad31f7'},
                    { color: '#fe44a4'},
                    { color: '#ff5b33'},
                    { color: '#fa195d'},
                    { color: '#30bf89'}
                ]
            }
        },
        methods: {
            cambiarColor(color){
                this.$vuetify.theme.themes.light.primary = color
                this.$vuetify.theme.themes.dark.primary = color
                this.guardarTema()
            },
            guardarTema () {
                this.$usuario.tema = {
                    color: this.$vuetify.theme.themes.light.primary,
                    isDark: this.$vuetify.theme.dark
                }
                localStorage.setItem(AuthConst.CURRENT_USER_KEY, JSON.stringify(this.$usuario))
                OperadoresService.updateOperador(this.$usuario)
            },
            cerrarSesion () {
                firebase.signOut()
                localStorage.removeItem(AuthConst.ACCESS_TOKEN_KEY)
                localStorage.removeItem(AuthConst.CURRENT_USER_KEY)
                this.$usuario = {}
                this.$router.push({ name: 'Login' })
            }
        }
    }
</script>

<style scoped>

</style>
