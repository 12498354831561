const dayjs = require('dayjs');
require('dayjs/locale/es');

const getFechaString = (fecha = new Date(), formato = 'YYYY-MM-DD') => {
  return dayjs(fecha).startOf('day').locale('es').format(formato);
}

const getFechaToDate = (fecha) => {
  return dayjs(fecha).startOf('day').toDate();
}

const enRangoDentroDeFechaLimite = (fechaStr, diasPosterioresAlActual = 1) => {
  // Boolean si fecha se encuentra dentro del límite de la fecha indicada en "diasPosterioresAlActual".
  let fechaLimite = dayjs().add(diasPosterioresAlActual, 'day').startOf('day');
  let fecha = dayjs(fechaStr);

  return fechaLimite.diff(fecha, 'day') >= 0;
}

export default { getFechaString, getFechaToDate, enRangoDentroDeFechaLimite }
