import db from '../config/Firebase'

let getCollection = (collection) => {
  return new Promise((resolve, reject) => {
    db.getCollection(collection).get()
      .then(snapshot => {
        if (snapshot.empty) {
          resolve([])
        } else {
          let documentos = []
          snapshot.forEach(doc => {
            let documento = doc.data()
            documento.id = doc.id
            documentos.push(documento)
          })
          resolve(documentos)
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

let buscarTodas = (collection, document) => {
  return new Promise((resolve, reject) => {
    db.getCollection(collection).where('usuario', '==', document.email).get()
      .then(snapshot => {
        if (snapshot.empty) {
          resolve([])
        } else {
          let documentos = []
          snapshot.forEach(doc => {
            let documento = doc.data()
            documento.id = doc.id
            documentos.push(documento)
          })
          resolve(documentos)
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

let getDoc = (collection, idDoc) => {
  return new Promise((resolve, reject) => {
    db.getCollection(collection).doc(String(idDoc)).get()
      .then((doc) => {
        if (doc.exists) {
          let documento = doc.data()
          documento.id = doc.id
          resolve(documento)
        } else {
          resolve(null)
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

let buscarPorCampo = (collection, valor, campo) => {
  return new Promise((resolve, reject) => {
    db.getCollection(collection).where(campo, '==', valor).get()
      .then(snapshot => {
        if (snapshot.empty) {
          resolve([])
        } else {
          let documentos = []
          snapshot.forEach(doc => {
            let documento = doc.data()
            documento.id = doc.id
            documentos.push(documento)
          })
          resolve(documentos)
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

let eliminar = (collection, documento) => {
  return new Promise((resolve, reject) => {
    db.getCollection(collection).doc(documento.id).delete()
      .then(() => {
        resolve(documento)
      })
      .catch(err => {
        reject(err)
      })
  })
}

let agregar = (collection, documento) => {
  return new Promise((resolve, reject) => {
    db.getCollection(collection).add(documento)
      .then((ref) => {
        documento.id = ref.id
        resolve(documento)
      })
      .catch(err => {
        reject(err)
      })
  })
}

let actualizar = (collection, documento) => {
  return new Promise((resolve, reject) => {
    db.getCollection(collection).doc(String(documento.id)).set(documento)
      .then(() => {
        resolve(documento)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export default { getCollection, buscarTodas, agregar, getDoc, buscarPorCampo,
  eliminar, actualizar }
