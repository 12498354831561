import crud from './CrudService';

const getFlotilla = () => {
  return crud.getCollection('flotilla')
}

const saveUnidad = (unidad) => {
  return crud.agregar('flotilla', unidad)
}

const updateUnidad = (unidad) => {
  return crud.actualizar('flotilla', unidad)
}

export default { getFlotilla, saveUnidad, updateUnidad }
