import crud from './CrudService';

const getOperadores = (flotilla) => {
  return new Promise(async (resolve, reject) => {
    try {
      let operadores = (await crud.getCollection('usuarios')).filter(usuario => !usuario.administrador)
      if (!flotilla) {
        flotilla = await crud.getCollection('flotilla')
      }
      operadores.forEach(operador => (operador.unidad = getFlotillaOperador(operador.idUnidad, flotilla)))

      resolve(operadores)
    } catch (e) {
      reject(e)
    }
  })
}

const saveOperador = (operador) => {
  return crud.agregar('usuarios', operador)
}

const updateOperador = (operador) => {
  return new Promise(async (resolve, reject) => {
    try {
      let operadorActualizado = await crud.actualizar('usuarios', operador)
      if (operadorActualizado.idUnidad) {
        operadorActualizado.unidad = await crud.getDoc('flotilla', operadorActualizado.idUnidad)
      }
      resolve(operadorActualizado)
    } catch (e) {
      reject(e)
    }
  })
}

function getFlotillaOperador(idUnidad, flotilla) {
  try {
    let index = flotilla.findIndex(unidad => unidad.id === idUnidad)
    return flotilla[index]
  } catch (e) {
    return null
  }
}

export default { getOperadores, saveOperador, updateOperador }
