import Vue from 'vue'
import AuthConst from "../config/AuthConst";

let datosGlobales = new Vue({
    data: {
        $loader: false,
        $usuario: {},
        $operadores: [],
        $flotilla: [],
        $rutas: {},
        $operadorParaGuardar: {},
        $dialogCrudOperador: false,
        $alerta: { model: false, ancho: '', color: '', icon: '', mensaje: '', isSnackbar: false },
        $verComoOperador: JSON.parse(localStorage.getItem(AuthConst.VISTA_OPERADOR_KEY)) || false
    }
})

Vue.mixin({
    computed: {
        $loader: {
            get () { return datosGlobales.$data.$loader },
            set (newValue) { datosGlobales.$data.$loader = newValue }
        },
        $usuario: {
            get () { return datosGlobales.$data.$usuario },
            set (newValue) { datosGlobales.$data.$usuario = newValue }
        },
        $operadores: {
            get () { return datosGlobales.$data.$operadores },
            set (newValue) { datosGlobales.$data.$operadores = newValue }
        },
        $flotilla: {
            get () { return datosGlobales.$data.$flotilla },
            set (newValue) { datosGlobales.$data.$flotilla = newValue }
        },
        $rutas: {
            get () { return datosGlobales.$data.$rutas },
            set (newValue) { datosGlobales.$data.$rutas = newValue }
        },
        $operadorParaGuardar: {
            get () { return datosGlobales.$data.$operadorParaGuardar },
            set (newValue) { datosGlobales.$data.$operadorParaGuardar = newValue }
        },
        $dialogCrudOperador: {
            get () { return datosGlobales.$data.$dialogCrudOperador },
            set (newValue) { datosGlobales.$data.$dialogCrudOperador = newValue }
        },
        $alerta: {
            get () { return datosGlobales.$data.$alerta },
            set (newValue) { datosGlobales.$data.$alerta = newValue }
        },
        $verComoOperador: {
            get () { return datosGlobales.$data.$verComoOperador },
            set (newValue) {
                localStorage.setItem(AuthConst.VISTA_OPERADOR_KEY, JSON.stringify(newValue))
                datosGlobales.$data.$verComoOperador = newValue
            }
        }
    },
    methods:{
        $getIndex (array, atributo, elementoABuscar) {
            return array.map(elemento => elemento[atributo]).indexOf(elementoABuscar[atributo])
        },
        $abrirDialogOperador (operador) {
            this.$operadorParaGuardar = JSON.parse(JSON.stringify(operador))
            this.$dialogCrudOperador = true
        },
        $showAlerta (ancho = '600', color = 'gray', icon = 'mdi-checkbox-blank-circle', mensaje = '', isSnackbar = false, accion = null, img = null) {
            this.$alerta.model = false
            this.$nextTick(() => {
                this.$alerta.ancho = ancho
                this.$alerta.color = color
                this.$alerta.icon = icon
                this.$alerta.img = img
                this.$alerta.mensaje = mensaje
                this.$alerta.accion = accion
                this.$alerta.model = true
                this.$alerta.isSnackbar = isSnackbar

                if (this.$alerta.isSnackbar){
                    let vm = this
                    setTimeout(() => {
                        vm.$alerta.model = false
                    }, 800)
                }
            })
        }
    }
});

Vue.prototype.$getZonaNombre = (clave) => {
    let zonasNombres = {
        'Z1': 'Zona 1',
        'Z2': 'Zona 2',
        'Z3': 'Zona 3',
        'Z4': 'Zona 4',
        'Z5': 'Zona 5',
        'Z6': 'Zona 6',
        'Z7': 'Zona 7',
        'Z8': 'Zona 8',
        'Z9': 'Zona 9',
        'Z10': 'Zona 10',
        'Z11': 'Zona 11',
        'Z12': 'Zona 12'

    };
    if (zonasNombres[clave])
        return zonasNombres[clave];

    return 'Zona desconocida';
}
Vue.prototype.$getZonaMunicipio = (clave) => {
    let zonasMunicipios = {
        'Z1': 'Cuautitlán, Cuautitlán Izcalli',
        'Z2': 'Atizapán',
        'Z3': 'Tlalnepantla',
        'Z4': 'Naucalpan',
        'Z5': 'Huixquilucan',
        'Z6': 'Miguel Hidalgo',
        'Z7': 'Álvaro Obregón',
        'Z8': 'Benito Juárez',
        'Z9': 'Coyoacán',
        'Z10': 'Cuauhtémoc',
        'Z11': 'La Magdalena Contreras',
        'Z12': 'Venustiano Carranza'

    };
    if (zonasMunicipios[clave])
        return zonasMunicipios[clave];

    return 'Fuera de rango';
}

